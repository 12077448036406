// Config’s job is to capture all the environment variables and make them available to the rest of the application.
const dotRelayChainEndpoint = process.env.NEXT_PUBLIC_DOT_RELAYCHAIN_ENDPOINT;
const dotRelayChainExplorerUrl =
  process.env.NEXT_PUBLIC_DOT_RELAYCHAIN_SUBSCAN ||
  `https://polkadot.js.org/apps/?rpc=${encodeURIComponent(dotRelayChainEndpoint)}#`;
const dotExplorerQuery = process.env.NEXT_PUBLIC_DOT_RELAYCHAIN_SUBSCAN ? "/block/" : "/explorer/query/";
const dotBifrostEndpoint = process.env.NEXT_PUBLIC_DOT_BIFROST_ENDPOINT;
const dotBifrostExplorerUrl =
  process.env.NEXT_PUBLIC_DOT_BIFROST_SUBSCAN ||
  `https://polkadot.js.org/apps/?rpc=${encodeURIComponent(dotBifrostEndpoint)}#`;
const dotBifrostExplorerQuery = process.env.NEXT_PUBLIC_DOT_BIFROST_SUBSCAN ? "/block/" : "/explorer/query/";

// Consolidate all the environment variables into a single object.
const config = {
  company: "Ava Protocol",
  website: "https://avaprotocol.org",
  appsURL: "https://app.avaprotocol.org",
  copyright: "Ava Protocol © 2024 , All Right Reserved",
  description:
    "Ava Protocol is an intent-centric EigenLayer AVS, empowering private autonomous super-transactions for Ethereum and beyond.",
  env: process.env.NEXT_PUBLIC_VERCEL_ENV || "development",

  emails: {
    contact: "contact@avaprotocol.org",
    partner: "partner@avaprotocol.org",
    collator: "collators@avaprotocol.org",
    developers: "developers@avaprotocol.org",
    teamGrowth: "team-growth@avaprotocol.org",
    teamEng: "team-eng@avaprotocol.org",
  },

  socialMedia: {
    telegramCommunity: {
      name: "Telegram",
      link: "https://t.me/ava_protocol",
      icon: "fontisto:telegram",
    },
    twitter: {
      name: "Twitter",
      link: "https://twitter.com/ava_protocol",
      handle: "@ava_protocol",
      icon: "bi:twitter-x",
    },
    discord: {
      name: "Discord",
      link: "https://discord.gg/7W9UDvsbwh",
      linkDevChannel: "https://discord.gg/d2BMEwCJvp",
      icon: "fontisto:discord",
    },
    youtube: {
      name: "YouTube",
      link: "https://www.youtube.com/@ava_protocol",
      icon: "bi:youtube",
    },
    linkedin: {
      name: "LinkedIn",
      link: "https://www.linkedin.com/company/avaprotocol",
      icon: "fontisto:linkedin",
    },
    github: {
      name: "Github",
      link: "https://github.com/AvaProtocol",
      icon: "fontisto:github",
    },
    email: {
      name: "Email",
      link: "mailto:contact@avaprotocol.org",
      icon: "fontisto:email",
    },
  },
  wallet: "https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Frpc.turing-staging.oak.tech#/explorer",
  docs: "/docs",
  career: "/jobs",
  github: "https://github.com/AvaProtocol",
  grantProgram: "https://github.com/AvaProtocol/Grants-Program",
  mediaKit: "https://drive.google.com/drive/folders/1mXBPPQGe0fYBOEMM03uM-DFCqZn9rz_W",
  dotCrowdloan: {
    endpoint: dotRelayChainEndpoint,
    paraToken: process.env.NEXT_PUBLIC_DOT_PARACHAIN_TOKEN,
    chainName: process.env.NEXT_PUBLIC_DOT_RELAYCHAIN_NAME,
    auctionNumber: +process.env.NEXT_PUBLIC_DOT_AUCTION_NUM,
    ss58Prefix: process.env.NEXT_PUBLIC_DOT_RELAYCHAIN_SS58 ? Number(process.env.NEXT_PUBLIC_DOT_RELAYCHAIN_SS58) : 0,
    bifrost: {
      explorer: {
        baseUrl: dotBifrostExplorerUrl,
        query: dotBifrostExplorerQuery,
        extrinsicQuery: "/extrinsic/",
      },
    },
    parallel: {
      explorer: {
        baseUrl: process.env.NEXT_PUBLIC_DOT_PARALLEL_SUBSCAN,
        extrinsicQuery: "/extrinsic/",
      },
    },
  },
  dotExplorer: {
    baseUrl: dotRelayChainExplorerUrl,
    query: dotExplorerQuery,
    extrinsicQuery: "/extrinsic/",
    accountQuery: "/account/",
  },
  parse: {
    serverURL: process.env.NEXT_PUBLIC_PARSE_SERVERURL,
    appId: process.env.NEXT_PUBLIC_PARSE_APPID,
  },
  googleTagManager: {
    id: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER,
  },
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  gleen: {
    id: process.env.NEXT_PUBLIC_GLEEN_ID,
  },
  languages: [
    { key: "en", name: "English" },
    { key: "ar", name: "العربية" },
    { key: "es", name: "Español" },
    { key: "fr", name: "Français" },
    { key: "ja", name: "日本語" },
    { key: "ko", name: "한국어" },
    { key: "pt", name: "Português" },
    { key: "vi", name: "Tiếng Việt" },
    { key: "zh-CN", name: "简体中文" },
  ],
  pathPrefixesWithoutLocale: ["/blog", "/docs", "/studio"],
};

export default config;
